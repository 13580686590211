.disabled > input,
.disabled > textarea {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    opacity: 1;
  }
  
  input {
    font-feature-settings: "tnum", "tnum";
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all 0.3s;
    width: 100%;
  }
  
  .form-group {
    display: grid;
    grid-row-gap: 5px;
    margin-bottom: 15px;
  }
  
  #file {
    border: none;
  }

  .hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    
  }
  