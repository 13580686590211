.nav {
  color: #fff;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}
.active-nav {
  /*color: #ff6000;*/
  text-decoration: none;
}

.body {
  background-color: rgb(88, 168, 95);
}

.footer,
.header {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7651435574229692) 0%,
    rgba(50, 50, 50, 1) 45%,
    rgba(255, 255, 255, 0.227328431372549) 100%
  );
  /* font-size: 17px; */
  font-size: var(--h2-font-size);
  color: #fff;
}

.contact {
  display: grid;
  /* margin: 5px; */
  margin: 0.5em 0;
  padding-bottom: 4px;
  /* margin-bottom: 10px; */
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: #ff6000 solid;
}

.company {
  justify-self: flex-start;
  margin-left: var(--margin-size);
}

div.phone {
  justify-self: flex-end;
  margin-right: var(--margin-size);
}

.navmenu {
  margin: 0.5em 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  padding: 4px 0;
  border-top: #5271ff solid;
  border-bottom: #5271ff solid;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #5271ff,
    0 0 82px #5271ff, 0 0 92px #5271ff, 0 0 102px #5271ff, 0 0 151px #5271ff;
}

.nav:after {
  background-color: #fff;
  display: block;
  content: '';
  height: 3px;
  width: 0%;
  -webkit-transition: width 0.3s ease-in-out;
  -moz--transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.nav:hover:after,
.nav:focus:after {
  width: 100%;
}

.messager-container {
  height: var(--messager-size);
  display: grid;
  justify-items: end;
}

.messager {
  position: absolute;
}

.messager a img {
  width: var(--messager-size);
  height: var(--messager-size);
  transition: 0.3s ease-out;
  margin-right: var(--margin-size);
}

.messager a img:hover {
  transform: scale(1.3);
}

CarouselImg {
  margin-bottom: 1em;
}

.img-container {
  display: grid;
  justify-content: center;
}

.ant-image-img {
  /* height: 100px; */
  height: var(--carousel-size);
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 40px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:focus {
  left: 20px;
  z-index: 2;
  color: white;
  opacity: 0.3;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:focus {
  right: 40px;
  z-index: 2;
  color: white;
  opacity: 0.3;
}
/*
@media (min-width: 600px) {
  .ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:hover {
  opacity: 1;
  transition: all 0.3s;
  
}
}
*/
.ant-carousel .slick-next:active,
.ant-carousel .slick-prev:active {
  opacity: 1;
  transition: all 0.3s;
}

.container {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
  padding: 0.5em;
}
.footer {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.227328431372549) 0%,
    rgba(50, 50, 50, 1) 45%,
    rgba(0, 0, 0, 0.7651435574229692) 100%
  );
}

.footer-container {
  display: grid;
  margin: 0.5em;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.footer-contact,
.footer-address {
  /* margin-top: 25px; */
  margin-top: 1em;
}

.footer-address {
  text-align: right;
}
.address,
.phone {
  color: #fff;
}

.joblist {
  text-align: center;
}
