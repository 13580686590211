img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.map {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.route-Img {
  margin-top: 20px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
}
