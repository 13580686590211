.homepage-title {
  font-size: var(--h2-font-size);
}

.homepage-title > h1 {
  text-align: justify;
  font-style: normal;
}

.homepage-title > h2 {
  text-align: center;
  margin-top: var(--margin-size);
}

.ant-list-split .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
  font-size: var(--h2-font-size);
}

.ant-list-item-meta {
  align-items: center;
}

.schema {
  margin-top: var(--margin-size);
  text-align: center;
  font-size: 1.5em;
}

.job {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 5px;
  margin-top: var(--margin-size);
  margin-bottom: var(--margin-size);
}

.job > div {
  padding-left: 5px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2) inset;
}

.job > div > h1,
.job > div > h2 {
  text-align: center;
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .job {
    display: grid;
    grid-template-columns: none;
    column-gap: 5px;
  }

  .job > div > h1,
  .job > div > h2 {
    text-align: left;
  }
}

.main > h1 {
  font-size: 150%;
  color: red;
  text-align: center;
  margin-bottom: 0;
}

.main > h1 > a:hover {
  color: red;
}

hr {
  height: 20px;
  margin: 20px auto;
  margin-top: 0;
  width: 70%;
  background-image: radial-gradient(
    farthest-side at 50% -50%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  position: relative;
  border: none;
}
hr:before {
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75) 50%,
    rgba(0, 0, 0, 0)
  );
}
